import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import ProjectBody from "../../components/ProjectBody"

export default function Twif({ data }) {
  const projectMeta = data.dataJson.meta[0];
  return (
    <>
      <Helmet>
        <title>{projectMeta.name} | {data.site.siteMetadata.title}</title>
      </Helmet>
      <Layout>
        <ProjectBody 
          projectData={data}
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    },
    allFile(filter: {relativeDirectory: {eq: "images/work/twif"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    },
    allDataJson {
      edges {
        node {
          meta {
            name
            id
            url
          }
        }
      }
    },
    dataJson(meta: {elemMatch: {id: {eq: "twif"}}}) {
      id
      meta {
        id
        name
        url
      }
      introduction {
        role
        description
        leadImage
      }
      projects {
        projectName
        blocks {
          item
          layout
          images {
            image
            shadow
            caption
          }
        }
      }
    }
  }
`